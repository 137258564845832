/* eslint-disable react/prop-types */
import style from "./btnCreateAccount.module.scss";
import Image from "next/image";
import { Fragment, useContext, useMemo } from "react";
import useOpenWidgetCreateAccount from "@hooks/magnetoUi/useOpenWidgetToApply";
import SpinLoad from "./spinner/component";
import classNames from "classnames";
import { TFunction, withTranslation } from "next-i18next";
import { AuthContext } from "@context";

type BtnCreateAccountProps = {
  classIcon?: string;
  color?: "green" | "purple" | "blue";
  fontSize?: number;
  iconType: "img" | "classIcon";
  img?: string;
  iconRight?: true;
  text: string;
  t: TFunction;
};

const BtnCreateAccount: React.FC<BtnCreateAccountProps> = ({
  classIcon,
  color,
  fontSize,
  iconType,
  img,
  iconRight,
  text,
  t
}) => {
  const { signUpLoading, openCreateAccount } = useOpenWidgetCreateAccount();
  const styles = classNames(style.btnCreateAccount, {
    [style.iconRight]: iconRight,
    [style.purple]: color == "purple",
    [style.blue]: color == "blue",
    [style.green]: color == undefined
  });

  const { isAuthenticated } = useContext(AuthContext);

  const iconValidate = useMemo(() => {
    const iconMap: Record<string, JSX.Element | null> = {
      classIcon: classIcon ? <i className={classIcon}></i> : null,
      img: img ? <Image src={img} alt={t<string>("genericTexts:altTexts.register")} width={25} height={25} /> : null
    };
    return iconMap[iconType] || null;
  }, [iconType, classIcon, img, t]);

  return (
    <Fragment>
      {!isAuthenticated && (
        <div className={style.containerBtnCreateAccount}>
          <button onClick={openCreateAccount} disabled={signUpLoading == true} className={styles}>
            {signUpLoading == true ? <SpinLoad /> : <Fragment>{iconValidate}</Fragment>}
            <span style={{ fontSize: `${fontSize}px` }}>{text}</span>
          </button>
        </div>
      )}
    </Fragment>
  );
};

export default withTranslation()(BtnCreateAccount);
